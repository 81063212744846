'use strict';

/**
 * AirPR (now Onclusive) Tracking is used to determine the success of 
 * PR campaigns by tracking pageviews, presumably generated by partnerships
 * with various publishers.
 * AirPR tracking should not be present on receipt pages (see PUB-4739)
 */

// private functions
// -----------------

/**
 * USAGE trackPageview: TTAM.module.airprTracking.trackPageview(name, languageCode);
 * @param {string} name - the name of the pageview event (eg, `store_cart`)
 * @param {string} languageCode - the language code of the pageview event (eg, `en-ca`)
 */
function trackPageview(name, languageCode){
    // make sure data-layer is in global scope before sending eventData
    if(window.dataLayer){
        window.dataLayer.push({
            'event': 'gaEvent',
            'gaEventCategory': 'airpr',
            'gaEventAction': name + '_pageview',
            'gaEventLabel': languageCode,
            'gaEventValue': 0,
            'gaEventNonInteractionBool': true
        });
    }
}

// public API
// ----------

module.exports = {
    trackPageview: trackPageview
};
